import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";
import http from "../../../src/Redux/services/http-common.jsx"

function CareplanSchedulepopup({
  scheduledata,
  refreshData,
  closeFun,
  handleSearch,
  closeSidebar,
}) {
  const props = scheduledata;
  const toast = useRef(null);
  console.log(moment(props.fromTime, "HH:mm").format("h:mm A"), props, "jhgkjbhklnjkl");

  const [doclist, setDocList] = useState("");
  const [modifiedServiceDate, setModifiedServiceDate] = useState();
  const [frommtime, setFromtime] = useState();
  const [serviceprovider, setServiceprovider] = useState();
  const [serviceproviderNo, setServiceproviderNo] = useState();
  const [comment, setComment] = useState();
  const [docavailabilityTime, setDocavailabilityTime] = useState();
  const [selectAvailabilityTime, setselectAvailabilityTime] = useState(null);

  const [selectConsult, setSelectConsult] = useState(null);
  const [selectdocList, setSelectdocList] = useState(null);
  const [selectlabList, setSelectlabList] = useState(null);
  const [selectStatus, setSelectStatus] = useState(null);
  const [selectStatus1, setSelectStatus1] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const [seoid, setSeoid] = useState();
  const [doctortlistApi, setDoctortlistApi] = useState();
  const [lablistApi, setlablistApi] = useState();
  const [nurselistApi, setnurselistApi] = useState();
  const [locationId, setLocationId] = useState(null);
  const [hospitslId, sethospitalId] = useState();
  const [locationIdNurse, setLocationIdNurse] = useState();
  const [hospitslIdNurse, sethospitalIdNurse] = useState();
  const [lablocationId, setlablocationId] = useState();
  const [labtestName, setlabtestName] = useState();
  const [selectlabtestName, setselectlabtestName] = useState();
  const [preferTime, setPreferTime] = useState("");
  const [preferDateTime, setPreferredDate] = useState();
  const [isset, isIsset] = useState(false);
  const [preferDate, setPreferDate] = useState();
  const [tickDialog, setTickDialog] = useState(false);
  const [ErrorDialog, setErrorDialog] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState(false);
  const [timeMessage, setTimeMessage] = useState("");
  const [error, setError] = useState(null);

  const [errorMessages, setErrorMessages] = useState({
    appointmentDate: "",
    scheduleTime: "",
    serviceProviderName: "",
    consultationReason: "",
    consultationType: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Update the state when props.serviceProviderName changes
    setServiceproviderNo(props.serviceProviderName || "");
  }, [props.serviceProviderName]);
  const [preferredLabTime, setPreferredLabTime] = useState([
    {
      label: "07.00 AM - 08.00 AM",

      value: "07.00 AM - 08.00 AM",
    },

    {
      label: "08.00 AM - 09.00 AM",

      value: "08.00 AM - 09.00 AM",
    },

    {
      label: "09.00 AM - 10.00 AM",

      value: "09.00 AM - 10.00 AM",
    },

    {
      label: "10.00 AM - 11.00 AM",

      value: "10.00 AM - 11.00 AM",
    },

    {
      label: "11.00 AM - 12.00 PM",

      value: "11.00 AM - 12.00 PM",
    },

    {
      label: "12.00 PM - 01.00 PM",

      value: "12.00 PM - 01.00 PM",
    },

    {
      label: "01.00 PM - 02.00 PM",

      value: "01.00 PM - 02.00 PM",
    },

    {
      label: "02.00 PM - 03.00 PM",

      value: "02.00 PM - 03.00 PM",
    },

    {
      label: "03.00 PM - 04.00 PM",

      value: "03.00 PM - 04.00 PM",
    },

    {
      label: "04.00 PM - 05.00 PM",

      value: "04.00 PM - 05.00 PM",
    },

    {
      label: "05.00 PM - 06.00 PM",

      value: "05.00 PM - 06.00 PM",
    },

    {
      label: "07.00 PM - 08.00 PM",

      value: "07.00 PM - 08.00 PM",
    },
  ]);

  // const [fromTime, setFromTime] = useState("");
  // const [toTime, setToTime] = useState("");

  // Custom function to generate time slots at 15-minute intervals
  const generateTimeSlots = () => {
    const timeSlots = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set the start time to midnight

    while (startTime.getHours() < 12) {
      const formattedTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      timeSlots.push(formattedTime);
      startTime.setMinutes(startTime.getMinutes() + 15);
    }

    return timeSlots;
  };

  const limitTimeForLab = (timeParam) => {
    console.log(timeParam, "sdpfdibdhpisdhpb");

    if (moment(preferDate).isSame(moment().format("YYYY-MM-DD"))) {
      let current_time_in_hour = moment().hour();

      let am_or_pm = timeParam.label.substring(0, 5).split(" ")[1];

      let time_in_hours = Number(timeParam.label.substring(0, 5).split(" ")[0]);

      if (am_or_pm == "PM" && time_in_hours != 12) {
        time_in_hours += 12;
      }

      if (current_time_in_hour + 3 >= time_in_hours) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const convertTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");

    let [hours] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}`;
  };

  const handleChange = (e) => {
    let value = e?.target?.value;

    setPreferTime(value);

    let time = convertTime(value.substring(0, 5));

    localStorage.setItem("preferTime", JSON.stringify(time));

    localStorage.setItem("preferValue", JSON.stringify(value));

    localStorage.setItem("preferDate", JSON.stringify(preferDate));
  };

  const changeDate = (date) => {
    setPreferDate(moment(date).format("yyyy-MM-DD"));
    date = moment(date).format("yyyy-MM-DD HH:mm:ss");

    setPreferredDate(date);
  };

  function handleEditClick() {
    setIsEditing(true);
  }

  useEffect(() => {
    let freeTextSearchValue = "";

    if (props.personnel === "Nutritionist") {
      freeTextSearchValue = "Nutrition and Dietetics";
    }
    let url = `${process.env.REACT_APP_ELASTIC_BASEURL}doctors?pageNo=1&pageSize=100`;

if (freeTextSearchValue) {
  url += `&freeTextSearch=${encodeURIComponent(freeTextSearchValue)}`;
}
    http
      .get(
       url
      )
      .then((res) => {
        setDoctortlistApi(res.data);

        let docList = res.data.map((obj) => {
          return obj.userName;
        });
        // let planids = res.data.map((obj) => {

        //   return (obj.planId)

        // })
        setDocList(docList);

        let seoId = res.data.map((obj) => {
          return obj.seoNameId;
        });
        setSeoid(seoId);

        console.log(res.data, "qwertymnbvcx");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);
  console.log(seoid, "doctortlist");

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}labs?latitude=20.2960587&longitude=85.8245398&source=b2c`
      )
      .then((res) => {
        setlablistApi(res.data);

        let locationIdLab = res.data.map((obj) => {
          return obj.locationId;
        });

        setlablocationId(locationIdLab);

        // let seoId = res.data.map((obj) => {

        //     return (obj.seoNameId)

        // })
        // setSeoid(seoId)


      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_BASEURL}user/list/filter?roleCode=NURSE&status=1`
      )
      .then((res) => {
        setnurselistApi(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  console.log(lablocationId, "qwertymnbvcx");

  const dropdownOptionConsult = [
    // { name: 'Eclinic', value: 'E' },
    { name: "Home Visit", value: "H" },
    { name: "Video", value: "V" },
    { name: "Phone", value: "P" },
  ];
  const dropdownOptionConsultnurse = [{ name: "Home Visit", value: "H" }];

  const dropdownOptionStatus = [
    // { name: "Yet to Start", value: "Yet to Start" },
    // { name: "In progress", value: "In progress" },
    { name: "Re-Scheduled", value: "6" },
    { name: "Completed", value: "3" },
    { name: "Cancelled", value: "5" },
  ];
  const dropdownOptionStatusPhonic = [

    { name: "Completed", value: "3" },
    { name: "Cancelled", value: "5" },
  ];

  const dropdownOptions = doctortlistApi?.map((item) => ({
    label: item?.userName,
    value: {
      id: item?.seoNameId,
      name: item?.userName,

      email: item?.email,
      code: item?.userId,
      number: item?.contactNo,
    },
  }));



  const dropdownOptionsParticularLab = labtestName?.map((item) => (

    {

      label: item?.labTestName,
      value: {
        name: item?.labTestName,
        labId: item?.labId,
        locationId: item?.locationId,
        labTestCode: item?.labTestCode,
        labName: item?.labName,
        locationName: item?.locationName,
        address1: item?.address1,
        address2: item?.address2,
        city: item?.city,
        state: item?.state,
        pinCode: item?.pinCode,
        labName: item?.labName,
      },
    }
  ));


  const dropdownOptionsNurse = nurselistApi?.map((item) => ({
    label: item?.userName,
    value: {
      id: item?.code,
      name: item?.userName,
      email: item?.email,
      code: item?.userId,
      number: item?.mobile,
    },
  }));
  const dropdownOptionsLab = lablistApi?.map((item) => ({
    label: item?.labName,
    value: {
      id: item?.locationId,
      name: item?.labName,
      email: item?.email,
      code: item?.userId,
      number: item?.contactNo,
    },
  }));
  const findServiceProvider = (serviceName) => {
    const foundProvider = dropdownOptionsNurse.find(option => option.label === serviceName);

    return foundProvider ? foundProvider.value : null;
  };
  const findServiceProviderDoc = (serviceName) => {
    const foundProvider = dropdownOptions.find(option => option.label === serviceName);

    return foundProvider ? foundProvider.value : null;
  };

  const findServiceProviderLab = (serviceName) => {
    const foundProvider = dropdownOptionsLab.find(option => option.label === serviceName);

    return foundProvider ? foundProvider.value : null;
  };

  const findServiceProviderLabTestName = (serviceName) => {
    const foundProvider = dropdownOptionsParticularLab?.find(option => {

      return option.label === serviceName;
    });
    console.log(foundProvider, "foundProvider");

    return foundProvider ? foundProvider.value : null;
  };



  const findLabTime = (serviceName) => {
    const foundProvider = preferredLabTime?.find(option => {
      console.log(option.label, serviceName, "foundProvider");
      return option.label === serviceName;
    });
    console.log(serviceName, "hjgfdhjgshj");

    return foundProvider ? foundProvider.value : null;
  };




  useEffect(() => {
    if (selectdocList) {
      fetchLocationId(selectdocList.id);
    }
  }, [selectdocList]);

  const fetchLocationId = (doctorId) => {

    http
      .get(
        `${process.env.REACT_APP_BASEURL}User_Hospital_Location/list?userId=${doctorId}&status=1&source=b2c`
      )
      .then((response) => {
        console.log(response.data[0], "apidata");

        let locationData = response.data[0].locationId;

        setLocationId(locationData);
        let hospitalIdData = response.data[0].hospitalId;
        sethospitalId(hospitalIdData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (serviceprovider) {
      fetchLocationIdNurse(serviceprovider.id);
    }
  }, [serviceprovider]);


  const fetchLocationIdNurse = (nurseId) => {
    // Make the GET request to fetch the location ID
    http
      .get(
        `${process.env.REACT_APP_BASEURL}User_Hospital_Location/list?userId=${nurseId}&status=1&source=Advertisement`
      )
      .then((response) => {
        console.log(response.data[0], "apidata");

        let locationDataNurse = response.data[0].locationId;

        setLocationIdNurse(locationDataNurse);
        let hospitalIdDataNurse = response.data[0].hospitalId;
        sethospitalIdNurse(hospitalIdDataNurse);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  const iconClassName = classNames("pi", "pi-calendar");

  const formatFromtime = frommtime ? moment(frommtime).format("HH:mm") : "";
  const updatedTime = moment(formatFromtime, "HH:mm")
    .add(15, "minutes")
    .format("HH:mm");

  const updatedToTimeDoc = moment(selectAvailabilityTime, "HH:mm")
    .add(15, "minutes")
    .format("HH:mm");
  console.log(updatedToTimeDoc, "time")

  var stringNumber = props.cpscSeqId;
  var number = parseInt(stringNumber);

  var appid = props.appointmentid;
  var appidnumber = parseInt(appid);
  const formatLabAppo = moment(preferDate).format("YYYY-MM-DD HH:mm:ss");
  console.log(updatedTime, updatedToTimeDoc, "time")

  const handleSave = (e) => {

    e.preventDefault();
    if (props.personnelCode === "NUR" && !selectStatus) {
      setError("* Please select a status");

      return;
    }
    http
      .post(
        `${process.env.REACT_APP_BASEURL}backOfficePatientAppointment/updateAppointment`,
        {
          patientId: props.patientCode,
          userId: selectdocList?.code ? selectdocList?.code : serviceprovider?.id ? serviceprovider?.id : props.userid,
          cpscSeqId: number,
          whenAppointment: bookedScheduledate ? bookedScheduledate : moment(props.modifiedServiceDate).format("MM/DD/YYYY"),
          fromTime: selectAvailabilityTime ? selectAvailabilityTime : formatFromtime ? formatFromtime : props.fromTime,
          toTime: updatedToTimeDoc ? updatedToTimeDoc : updatedTime ? updatedTime : props.toTime,
          status: 1,
          modifiedby: props.patientCode,
          id: appidnumber,
          consultationsType: props.consultationType,
          userName: selectdocList?.name ? selectdocList?.name : serviceprovider?.name ? serviceprovider?.name : props.serviceProviderName,
          userMobile: selectdocList?.number ? selectdocList?.number : serviceprovider?.number ? serviceprovider?.number : props.serviceProviderMobile,

          serviceStatus: selectStatus == null ? 6 : selectStatus,
          consultationsReason: comment ? comment : props.comments
        }
      )

      .then((response) => {

        setDialog(false);

        handleSearch();

        closeSidebar();


      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle any errors
      });
  };

  const handleUpdateLab = (e) => {


    e.preventDefault();

    http
      .post(
        `${process.env.REACT_APP_BASEURL}MedicarePatientCart/updateLabAppointment`,
        {

          cpscSeqId: props.cpscSeqId,
          orderId: props.laborderId,
          patientId: props.patientCode,
          hospitalId: selectlabtestName?.labId ? selectlabtestName?.labId : props.hospitalIdLab,
          locationId: selectlabtestName?.locationId ? selectlabtestName?.locationId : props.locationId,
          preferDateTime: formatLabAppo ? formatLabAppo : props.modifiedServiceDate,
          status: 1,
          modifiedBy: props.patientCode,
          hospitalName: selectlabList?.name ? selectlabList?.name : props.serviceProviderName,
          serviceStatus: 6,
          fromTime: startTimeFormat ? startTimeFormat : props?.fromTime,
          toTime: endTimeFormat ? endTimeFormat : props.toTime,
          patientLabTestsOrderDetailsList: [
            {
              patientId: props.patientCode,
              patientLabTestOrderId: props.laborderId,
              labTestName: selectlabtestName?.name ? selectlabtestName?.name : props.labTestName,
              hospitalId: selectlabtestName?.labId ? selectlabtestName?.labId : props.hospitalIdLab,
              hospitalName: selectlabList?.name ? selectlabList?.name : props.serviceProviderName,
              locationId: selectlabtestName?.locationId ? selectlabtestName?.locationId : props.locationId,
              status: 1,

              id: props.labTestId,
              modifiedBy: props.patientCode,
            }
          ]
        }

      )

      .then((response) => {
        setDialog(false);

        handleSearch();

        closeSidebar();
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle any errors
      });

  };

  // const handleUpdate = (e) => {
  //   e.preventDefault();
  //   http
  //     .post(
  //       `${process.env.REACT_APP_BASEURL}backOfficePatientAppointment/updateNurseAppointment`,
  //       {
  //         patientCode: props.patientCode,
  //         cpscSeqId: number,
  //         serviceStatus: selectStatus || selectStatus1,
  //       }
  //     )
  //     .then((response) => {
  //       setDialog(false);
  //       closeFun();
  //       handleSearch();
  //       closeSidebar();
  //     })
  //     .catch((error) => {
  //       console.error("Error uploading file:", error);
  //       // Handle any errors
  //     });
  // };

  const userCode = selectdocList ? selectdocList?.code : "";

  const bookedScheduledate = modifiedServiceDate
    ? moment(modifiedServiceDate).format("MM/DD/YYYY")
    : "";

  console.log(selectConsult, "qwerty");

  const handleValidation = () => {
    let hasError = false;
    const newErrorMessages = {
      appointmentDate: "",
      scheduleTime: "",
      serviceProviderName: "",
      consultationReason: "",
      consultationType: "",
    };

    // Validate appointment date
    if (isEditing) {
      if (props.personnelCode === "LAB" && !preferDate) {
        newErrorMessages.appointmentDate = " * Appointment date is required";
        hasError = true;
      }
      // Add any additional validation for preferDate specific to "Lab" personnel code here
      else if (props.personnelCode !== "LAB" && !modifiedServiceDate) {
        newErrorMessages.appointmentDate = "* Appointment date is required";
        hasError = true;
      }
    }

    // Validate schedule time
    if (isEditing) {
      if (
        props.personnelCode === "LAB" &&
        !preferTime &&
        !selectAvailabilityTime
      ) {
        newErrorMessages.scheduleTime = " * Select a time for the appointment";
        hasError = true;
      } else if (
        props.personnelCode !== "LAB" &&
        (!frommtime || timeMessage !== "") &&
        !selectAvailabilityTime
      ) {
        newErrorMessages.scheduleTime = "* Select a valid schedule time";
        hasError = true;
      }
    }


    if (isEditing && props.personnelCode !== "LAB" && !comment) {
      newErrorMessages.consultationReason = "* Consultation reason is required";
      hasError = true;
    }

    // Validate consultation type (skip for "LAB" personnel code)
    if (isEditing && props.personnelCode !== "LAB" && !selectConsult) {
      newErrorMessages.consultationType = "* Select a consultation type";
      hasError = true;
    }

    if (isEditing) {
      if (
        props.personnelCode === "DR" &&
        props.serviceStatus === "4" &&
        !props.serviceProviderName
      ) {
        newErrorMessages.serviceProviderName =
          "* Service Provider Name is required";
        hasError = true;
      } else if (props.personnelCode === "DR" && !selectdocList) {
        newErrorMessages.serviceProviderName = "* Select a Doctor";
        hasError = true;
      } else if (props.personnelCode === "LAB" && !selectlabList) {
        newErrorMessages.serviceProviderName = "* Select a Lab";
        hasError = true;
      } else if (props.personnelCode === "NUR" && !serviceprovider) {
        newErrorMessages.serviceProviderName = "* Select a Nurse";
        hasError = true;
      }
    }

    setErrorMessages({ ...errorMessages, ...newErrorMessages });

    if (!hasError) {
      setDialog(true); // Open the edit box if there are no errors
    } else {
      setIsEditing(true); // Open the "Edit Information" section if there are errors
    }

    return hasError;


  };

  const dropdownStyle = {
    width: '200px', // Set the desired width here
  };

  const docavailabilityDate = moment(props?.modifiedServiceDate).format("MM/DD/YYYY")

  useEffect(() => {

    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getDoctorAvailability`,
        {
          userId: selectdocList?.code,
          fromDate: bookedScheduledate ? bookedScheduledate : docavailabilityDate,
        }
      )
      .then((res) => {

        setDocavailabilityTime(res.data, "response");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    // } else {

    //   console.log("Waiting for both selectdocList and formatFromtime to be set");
    // }
  }, [selectdocList, bookedScheduledate]);

  // const generateDropdownOptions = (data) => {
  //   return data?.map((time) => ({ label: time, value: time }));
  // };


  // const dropdownAvailabilityTime = Object.entries(docavailabilityTime)
  // ?.filter(([time, value]) => value === 'Y')
  // ?.map(([time, value]) => ({ label: time, value: time }));




  console.log(selectAvailabilityTime, "selectAvailabilityTime")


  const handleSubmit = (e, submit) => {
    e.preventDefault();

    const hasError = handleValidation();

    if (!hasError) {
      setLoading(true);
      http
        .post(
          `${process.env.REACT_APP_BASEURL}backOfficePatientAppointment/bookAppointment`,
          {
            consultationsReason: comment,

            consultationsType: selectConsult,

            createdBy: props.patientCode,

            fromTime: selectAvailabilityTime,

            hospitalId: hospitslId,

            locationId: locationId,
            cheBranchId: props.chelocationid,
            cheCenterId: props.cheid,
            modifiedBy: props.patientCode,

            //     patientEmail:"hridesha123@gmail.com",

            patientId: props.patientCode,

            patientMobileNo: props.mobile,

            patientName: props.patientName,
            cpscSeqId: number,
            status: 1,

            toTime: updatedToTimeDoc,

            userEmail: selectdocList.email,

            userId: selectdocList.code,

            userName: selectdocList.name,

            userMobile: selectdocList.number,
            serviceName: props.serviceName,

            // "userQualification": "MBBS",

            // "userSalutation": "Dr",

            whenAppointment: bookedScheduledate,

            // "relation": "sister",

            payMode: "Cash",
          }
        )
        .then((response) => {
          setDialog(false);
          closeFun();
          handleSearch();
          setLoading(false);
          closeSidebar();
        })
        // .catch((error) => {
        //   if (error.response && error.response.status === 400) {
        //     console.log(error.response?.data.details[0], "fvgvhscbcjnk"); // Use error.response instead of response

        //     setErrorDialog(true);
        //     // setDialog(false);

        //     setErrorMsg(error.response?.data.details[0]);

        //     toast(error.response?.data.details[0]); // Use error.response instead of response
        //   } else {
        //     console.error(error);
        //   }
        // });
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setErrorDialog(true);
            setErrorMsg(error.response?.data.details[0]);
            toast(error.response?.data.details[0]);
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          // setLoading(false); // Set loading to false regardless of success or failure
        });

    }
  };

  const handleNurse = (e) => {
    e.preventDefault();
    const hasError = handleValidation();

    if (!hasError) {
      setLoading(true);
      http
        .post(
          `${process.env.REACT_APP_BASEURL}backOfficePatientAppointment/bookAppointment`,
          {
            consultationsReason: comment,

            consultationsType: selectConsult,

            createdBy: props.patientCode,

            fromTime: formatFromtime,

            hospitalId: hospitslIdNurse,

            locationId: locationIdNurse,

            modifiedBy: props.patientCode,

            //     patientEmail:"hridesha123@gmail.com",

            patientId: props.patientCode,
            cheBranchId: props.chelocationid,
            cheCenterId: props.cheid,
            patientMobileNo: props.mobile,

            patientName: props.patientName,
            cpscSeqId: number,
            status: 1,

            toTime: updatedTime,

            userEmail: "",

            userId: serviceprovider?.id,

            userName: serviceprovider?.name,

            userMobile: serviceprovider?.number,
            serviceName: props.serviceName,

            // "userQualification": "MBBS",

            // "userSalutation": "Dr",

            whenAppointment: bookedScheduledate,

            // "relation": "sister",

            payMode: "Cash",
          }
        )
        .then((response) => {
          // console.log(response.data, "sospost")

          setDialog(false);
          closeFun();
          setLoading(false);
          handleSearch();
          closeSidebar();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            console.log(error.response?.data.details[0], "fvgvhscbcjnk");

            setErrorDialog(true);
            // setDialog(false);

            setErrorMsg(error.response?.data.details[0]);
          } else {
            console.error(error);
          }
        });
    }
  };

  const [startTime, endTime] = preferTime.split(" - ");
  const startTimeFormat = moment(startTime, ["h A"]).format("HH:mm");
  const endTimeFormat = moment(endTime, ["h A"]).format("HH:mm");
  console.log(startTimeFormat, endTimeFormat, "asdfgghjjkkl");

  const handleLabSubmit = (e) => {
    const moment = require("moment");
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const formatLabAppo = moment(preferDate).format("YYYY-MM-DD HH:mm:ss");
    const preferTimeformat = console.log(currentTime, "asddfgg");

    const hasError = handleValidation();

    if (!hasError) {
      setLoading(true);
      http
        .post(`${process.env.REACT_APP_BASEURL}MedicarePatientCart/`, {
          cpscSeqId: number,
          patientId: props.patientCode,
          // userId: "AEJYVSSK080322",
          // prescriptionId: 0,

          cheid: props.cheid,
          chelocationId: props.chelocationid,
          hospitalId: selectlabtestName.labId,
          locationId: selectlabtestName.locationId,
          preferDateTime: formatLabAppo,
          patientCheId: props.cheid,
          patientCheLocationId: props.chelocationid,
          // preferTime: preferTime,
          fromTime: startTimeFormat,
          toTime: endTimeFormat,
          status: 1,
          serviceName: props.serviceName,
          createdBy: props.patientCode,
          modifiedBy: props.patientCode,
          createdDate: currentTime,
          modifiedDate: currentTime,
          patientLabTestsOrderDetailsList: [
            {
              patientId: props.patientCode,
              // patientLabTestOrderId: "187052917",
              // patientLabTestOrderIds: null,
              labTestCode: selectlabtestName.labTestCode,
              labTestName: selectlabtestName.name,
              hospitalId: selectlabtestName.labId,
              locationId: selectlabtestName.locationId,
              status: 1,
              createdBy: props.patientCode,
              modifiedBy: props.patientCode,
              createdDate: currentTime,
              modifiedDate: currentTime,
              hospitalName: selectlabList.name,
              locationName: selectlabtestName.locationName,
              // "hospitalLogo": null,
              address1: selectlabtestName.address1,
              address2: selectlabtestName.address2,
              city: selectlabtestName.city,
              state: selectlabtestName.state,
              country: null,
              // "hospitalContactNumber": null,
              pinCode: selectlabtestName.pinCode,
              // "patientGender": "F",
              patientMobile: props.mobile,
              patientAddress1: props.address1,
              patientAddress2: props.address2,
              patientCity: props.city,
              patientState: props.state,
              patientCountry: props.country,
              // "labtestcategoryId": 0,
              // "labtestdiseaseId": 0,
              // "paymentLinkForPatient": "N",
              patientName: props.patientName,
            },
          ],
          // "technicianAssigned": null,
          // "technicianContactNo": null,
          // "locationCurebayCenter": "N",
          // "sourceChannel": null,
          // "patientPhoto": "https://storage.googleapis.com/curebay-nonprod-application-data/PHOTO653503112022063839848.JPEG",
          patientName: props.patientName,
          // "patientDob": "2004-03-10 00:00:00",
          // "patientSalutation": "Ms.",
          // "patientGender": "F",
          patientEmail: props.email,
          patientMobile: props.mobile,
          patientAddress1: props.address1,
          patientAddress2: props.address2,
          patientCity: props.city,
          patientState: props.state,
          patientCountry: props.country,
          // "patientPinCode": 751009,
          // "hospitalPinCode": 0,
          hospitalName: selectlabList.name,
          locationName: selectlabtestName.city,
          // "hospitalContactNumber1": null,
          // "hospitalEmail": null,
          // "hospitalCountry": null,
          // "latitude": 20.265856,
          // "longitude": 85.83684
        })
        .then((response) => {
          // setDialog(false);
          // toast("Appointment Booked")
          setLoading(false);
          setDialog(false);
          closeFun();

          handleSearch();
          closeSidebar();
        })
        .catch((error) => console.error(error));

    }
  };



  useEffect(() => {
    if (selectlabList) {
      fetchlabLocationId(selectlabList.id);
    }
  }, [selectlabList]);
  // console.log(selectlabList.id,"location")

  const fetchlabLocationId = (labId) => {
    // Make the GET request to fetch the location ID
    http
      .get(`${process.env.REACT_APP_ELASTIC_BASEURL}labs/${labId}/labtest?all=true`)
      .then((response) => {
        console.log(response.data, "apidata122");

        setlabtestName(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onFilter = (event) => {
    if (event.query) {
      const filteredOptions = dropdownOptions.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelectdocList(filteredOptions);
    } else {
      setSelectdocList(dropdownOptions);
    }
  };
  const onFilterLab = (event) => {
    if (event.query) {
      const filteredOptionsLab = dropdownOptionsLab.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelectlabList(filteredOptionsLab);
    } else {
      setSelectlabList(dropdownOptionsLab);
    }
  };
  const onFilterLabTest = (event) => {
    if (event.query) {
      const filteredOptionsLabTest = dropdownOptionsParticularLab.filter(
        (option) =>
          option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setselectlabtestName(filteredOptionsLabTest);
    } else {
      setselectlabtestName(dropdownOptionsParticularLab);
    }
  };

  const toastBC = useRef(null);

  const clear = (submit) => {
    toastBC.current.clear();
    submit && show();
  };

  const show = () => {
    toast.current.show({
      severity: "success",
      summary: "Submission Received",
      detail: "Thank you, we have received your submission.",
    });
  };

  const closeBothFunct = () => {
    setDialog(false);
    closeFun();
  };

  const currentDate = moment().startOf("day"); // Get the current date at the start of the day
  const effectiveStartDate = props.effectiveStartDate
    ? moment(props.effectiveStartDate).startOf("day")
    : "";

  const minDatenew = effectiveStartDate.isBefore(currentDate)
    ? currentDate.toDate()
    : effectiveStartDate.toDate();

  const minDatenew1 = new Date();


  const currentTime = moment();


  const modifiedServiceDateMoment = moment(modifiedServiceDate);


  const isToday = modifiedServiceDateMoment.isSame(currentTime, "day");

  //   const minTime = isToday ? currentTime.toDate() : undefined;
  //   const maxTime = isToday ? currentTime.endOf("day").toDate() : undefined;

  //   const modifiedServiceDateMoment = moment(modifiedServiceDate);
  // const isToday = modifiedServiceDateMoment.isSame(currentTime, "day");

  // Set the min and max times based on your conditions
  let minTime = moment().startOf("day").hour(7); // 7:00 AM
  let maxTime = moment().startOf("day").hour(21); // 9:00 PM

  if (isToday) {
    // If the modified service date is today, update minTime to be the current time
    minTime = moment().max(currentTime);
  }

  const onModifyDate = (e) => {
    setModifiedServiceDate(e)
    onChangedDocList(e)
  }
  const onChangedDocList = (e) => {

    let freeTextSearchValue = "";

    if (props.personnel === "Nutritionist") {
      freeTextSearchValue = "Nutrition and Dietetics";
    }

    let url = `${process.env.REACT_APP_ELASTIC_BASEURL}doctors?pageNo=1&pageSize=100`;

if (freeTextSearchValue) {
  url += `&freeTextSearch=${encodeURIComponent(freeTextSearchValue)}`;
}
    http
      .get(url)
      .then((res) => {
        let docURL = `${process.env.REACT_APP_COMMON_BASEURL}DoctorListAsPerLeave/list?leaveDate=${moment(e).format("yyyy/MM/DD")}`
        http.get(docURL).then((res2) => {
          if (res2.data && res2.data.length) {
            let withoutLeave = res.data.filter(item1 => !res2.data.some(item2 => item1.userId.toLowerCase() === item2.userId.toLowerCase()));
            setDoctortlistApi(withoutLeave);
            let docList = withoutLeave.map((obj) => {
              return obj.userName;
            });
            setDocList(docList);
            let seoId = withoutLeave.map((obj) => {
              return obj.seoNameId;
            });
            setSeoid(seoId);
          } else {
            setDoctortlistApi(res.data);
            let docList = res.data.map((obj) => {
              return obj.userName;
            });
            setDocList(docList);
            let seoId = res.data.map((obj) => {
              return obj.seoNameId;
            });
            setSeoid(seoId);
          }
        })
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });

  }
  console.log(serviceprovider?.number, "service")
  return (
    <>
      <ToastContainer />
      <Toast ref={toast} />
      <Toast ref={toastBC} position="bottom-center" />

      {/* <button label="Success" className="p-button-success" onClick={showSuccess} >gvbnjmk,</button> */}

      <div className="">
        <form>
          <div>
            <h2 style={{ color: "black", marginTop: "0px" }}>
              {" "}
              Add Appointment Details
            </h2>
            {props.serviceStatus === "3" ? "" : (
              <button
                type="button"
                onClick={handleEditClick}
                className="editinfor"
              >
                Edit information
              </button>
            )
            }
          </div>
          {/* <button>Edit </button> */}
          <div className="row ">
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Effective Start Date :</label>
              </div>
              <div style={{ width: "60%" }}>
                {props?.effectiveStartDate
                  ? moment(props?.effectiveStartDate).format("DD-MM-YYYY")
                  : ""}
              </div>
            </div>

            {props.serviceStatus === "4" ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Requested Date :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {props.modifiedServiceDate
                    ? moment(props.modifiedServiceDate).format("DD-MM-YYYY")
                    : ""}
                </div>
              </div>
            ) : (
              " "
            )}

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Appointment date : </label>
              </div>
              <div>
                {isEditing ? (
                  <>
                    {props.personnelCode === "LAB" ? (
                      <DatePicker
                        id="fromDate"
                        name="fromDate"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        minDate={minDatenew}
                        // className="pt-2 text-gray-900 "

                        dateFormat="dd-MM-yyyy"
                        // value={moment(preferDate).format("DD-MM-YYYY")}
                        onSelect={changeDate}
                        disabledKeyboardNavigation={true}
                        autoFocus={false}
                        placeholderText="select"
                        // placeholderText="Select Date"
                        customInput={
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              type="text"
                              className="inputfield"
                              // className="form-control modifieddate"
                              name="whenAppointment"
                              value={
                                preferDate
                                  ? moment(preferDate).format("DD-MM-YYYY")
                                  :
                                  props.modifiedServiceDate == null ? "" :
                                    moment(props?.modifiedServiceDate).format("DD-MM-YYYY")
                              }
                              readOnly
                            />
                            <i
                              className="fa fa-calendar  fa-pencil-square-o1 fontsome123"
                              aria-hidden="true"
                            ></i>
                          </div>
                        }
                      />
                    ) : (
                      <DatePicker
                        showIcon
                        selected={modifiedServiceDate}
                        minDate={minDatenew}
                        onChange={(e) => onModifyDate(e)}
                        placeholderText="select"
                        customInput={
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              type="text"
                              className="inputfield"
                              // className="form-control modifieddate"
                              name="whenAppointment"
                              value={
                                modifiedServiceDate
                                  ? modifiedServiceDate.toLocaleString(
                                    "en-GB",
                                    {
                                      day: "numeric",
                                      month: "numeric",
                                      year: "numeric",
                                    }
                                  )
                                  :
                                  props?.modifiedServiceDate == null ? "" :
                                    moment(props?.modifiedServiceDate).format("DD-MM-YYYY")
                              }
                              readOnly
                            />
                            <i
                              className="fa fa-calendar  fa-pencil-square-o1 fontsome123"
                              aria-hidden="true"
                            ></i>
                          </div>
                        }
                      />
                    )}
                  </>
                ) : props.serviceStatus === "4" ? (
                  ""
                ) : props.modifiedServiceDate ? (
                  moment(props.modifiedServiceDate).format("DD-MM-YYYY")
                ) : (
                  ""
                )}
                <div style={{ color: "red", marginTop: "12px", fontSize: "14px" }}>
                  {errorMessages.appointmentDate}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Service Provider Name :</label>
              </div>
              <div style={{ width: "60%" }}>
                {isEditing ? (
                  <>
                    {
                      props.personnelCode === "DR" &&
                        props.serviceStatus === "4" ? (
                        props.serviceProviderName
                      ) : props.personnelCode === "DR" ? (
                        <Dropdown
                          id="disease"
                          name="docnames"
                          value={selectdocList ? selectdocList : findServiceProviderDoc(props.serviceProviderName)}
                          // value={   props?.serviceProviderName ? props?.serviceProviderName : selectdocList}
                          options={dropdownOptions}
                          onChange={(e) => setSelectdocList(e.value)}
                          // placeholder="Select Doctor"
                          placeholder="Select Doctor"
                          className="dropdowntd"
                          optionLabel="label"
                          filter
                          filterBy="label"
                          filterMatchMode="contains"
                          filterPlaceholder="Type to filter"
                          filterInputAutoFocus
                          onFilter={onFilter}
                        />
                      ) : props.personnelCode === "LAB" ? (
                        <Dropdown
                          id="disease"
                          name="labpartners"
                          // value={props.serviceProviderName ? props.serviceProviderName : selectlabList}
                          value={selectlabList ? selectlabList : findServiceProviderLab(props.serviceProviderName)}
                          options={dropdownOptionsLab}
                          onChange={(e) => setSelectlabList(e.value)}
                          placeholder="Select Lab"

                          className="dropdowntd"
                          optionLabel="label"
                          filter
                          filterBy="label"
                          filterMatchMode="contains"
                          filterPlaceholder="Type to filter"
                          filterInputAutoFocus
                          onFilter={onFilterLab}
                        />
                      ) : props.personnelCode === "NUR" ? (
                        <Dropdown
                          id="disease"
                          name="nursenames"

                          value={serviceprovider ? serviceprovider : findServiceProvider(props.serviceProviderName)}

                          options={dropdownOptionsNurse}
                          onChange={(e) => setServiceprovider(e.target.value)}
                          placeholder="Select Nurse"
                          className="dropdowntd"
                          optionLabel="label"

                        />
                      ) : (
                        ""
                      )

                      // <input type="text" name="userName" onChange={(e) => setServiceprovider(e.target.value)}
                      //     value={serviceprovider}

                      //     placeholder=" Service provider Name" className="inputfield" />
                    }
                  </>
                ) : props.serviceProviderName === null ? (
                  ""
                ) : (
                  props.serviceProviderName
                )}

                <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                  {errorMessages.serviceProviderName}
                </div>

                {/* <input type="text" placeholder="service Provider Name" className="inputfield" value={props.serviceProviderName}/> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Schedule Time :</label>
              </div>
              <div style={{ width: "60%" }}>
                {isEditing ? (
                  <>
                    <div>
                      {props.personnelCode === "LAB" ? (
                        <>
                          <select
                            // class=" text-xs h-10 bg-white border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 w-full"
                            className="dropdowntd"
                            id="procedureStatus"
                            style={{ marginTop: "20px" }}
                            name="labtime"
                            // value={preferTime ? preferTime : findLabTime((moment(props.fromTime, "HH:mm").format("hh:mm A")) - (moment(props.toTime,"HH:mm").format("hh:mm A")))}
                            value={
                              preferTime ? preferTime :
                                findLabTime(`${moment(props.fromTime, "HH.mm").format("hh.mm A")} - ${moment(props.toTime, "HH.mm").format("hh.mm A")}`)
                            }



                            onChange={handleChange}
                          >
                            <option value="">
                              {/* { props.fromTime ? props.fromTime :" Select time"}  */}
                            </option>

                            {preferredLabTime.map((res, i) => (
                              <option
                                key={i}
                                // className="py-1 text-sm text-green-600 font-semibold"

                                value={res.value}
                                className={
                                  limitTimeForLab(res)
                                    ? "disabled-option calander"
                                    : "enabled-option calander"
                                }
                                disabled={limitTimeForLab(res)}
                              >
                                {res.label}
                              </option>
                            ))}
                          </select>
                          <span
                            style={{ color: "red", fontSize: "12px" }}
                          ></span>
                        </>
                      ) :
                        props.personnelCode === "DR" && docavailabilityTime && selectdocList ? (

                          <Dropdown
                            id="disease"
                            name="doctime"
                            // value={ props.fromTime ? props.fromTime :selectAvailabilityTime}
                            value={selectAvailabilityTime}
                            // options={docavailabilityTime?.map((time) => ({ label: time, value: time }))}
                            options={Object.keys(docavailabilityTime)?.map((time) => ({
                              label: time,
                              value: time,
                              disabled: docavailabilityTime[time] === "N",
                            }))}

                            onChange={(e) => setselectAvailabilityTime(e.value)}
                            placeholder="Select Available Time"
                            // placeholder="Select Available Time"
                            className="dropdowntd"
                            optionLabel="label"

                          />

                        ) : props.personnelCode === "NUR" ? (

                          <DatePicker
                            selected={frommtime ? frommtime : props.fromTime == null ? "" : moment(props.fromTime, "HH:mm").toDate()}
                            className="inputfield"
                            onChange={(date) => setFromtime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="h:mm aa"
                            timeIntervals={15}
                            timeCaption="Time"
                            minTime={minTime.toDate()}
                            maxTime={maxTime.toDate()}
                            customTimeInput={<input readOnly />}
                            placeholder="Add Time"
                            name="nursetime"
                          />
                        ) : ""}
                      <div style={{ color: "red", marginTop: "10px", fontSize: "14px" }}>
                        {errorMessages.scheduleTime}
                      </div>
                    </div>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {timeMessage}
                    </div>
                  </>
                ) : (
                  props.fromTime
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Service Provider Category :</label>
              </div>
              <div style={{ width: "60%" }}>{props.personnel}</div>
            </div>



            {props.personnelCode === "LAB" && selectlabList ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Lab Test Name :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (

                    <Dropdown
                      id="disease"
                      name="labtestname"

                      value={selectlabtestName ? selectlabtestName : findServiceProviderLabTestName(props?.labTestName)}
                      options={dropdownOptionsParticularLab}
                      onChange={(e) => setselectlabtestName(e.value)}
                      placeholder="Select Lab Test Name" labTestName
                      className="dropdowntd"
                      optionLabel="label"
                      filter
                      filterBy="label"
                      filterMatchMode="contains"
                      filterPlaceholder="Type to filter"
                      filterInputAutoFocus
                      onFilter={onFilterLabTest}
                      dropdownStyle={dropdownStyle}
                    />
                  ) : (
                    props.labTestName
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {props.personnelCode === "LAB" ? (
              ""
            ) : (
              selectdocList || serviceprovider ?
                <div
                  style={{
                    display: "flex",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <label>Service Provider No :</label>
                  </div>
                  <div style={{ width: "60%" }}>
                    {isEditing ? (
                      <>
                        {props.personnelCode === "DR" && selectdocList ? (
                          <>{selectdocList?.number}</>
                        )
                          // : isEditing ?   props.serviceProviderMobile
                          :
                          props.personnelCode === "NUR" && serviceprovider ? (
                            <>{serviceprovider?.number}</>
                          ) : (
                            // <input
                            //   type="tel"
                            //   name="userName"
                            //   onChange={(e) => setServiceproviderNo(e.target.value)}
                            //   value={serviceproviderNo ?  serviceproviderNo :  props.serviceProviderMobile}
                            //   placeholder=" Service provider No."
                            //   className="inputfield"
                            // /> 
                            props.serviceProviderMobile
                          )}

                        {/* 
                                            <Dropdown onChange={(e) => setSelectdocList(e.value)} options={dropdownOptiondoc} optionLabel="name" name="Service Provider Name" value={selectConsult}
                                                placeholder={"Select Doclist"} className="dropdowntd" /> */}
                      </>
                    ) : props.serviceProviderMobile === null ? (
                      ""
                    ) : (
                      props.serviceProviderMobile
                    )}

                    {/* <input type="text" placeholder="Service Provider Number" className="inputfield" value={props.serviceProviderMobile}/> */}
                  </div>
                </div> : ""
            )}

            {props.personnelCode === "LAB" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Consultation Reason :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <>
                      <textarea
                        cols={32}
                        type="text"
                        name="comment"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment ? comment : props.comments}
                        className="inputfield"
                      />
                    </>
                  ) : (
                    props.comments
                  )}

                  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                    {errorMessages.consultationReason}
                  </div>

                  {/* <i className="fa fa-pencil-square-o fa-pencil-square-o1 fontsome" aria-hidden="true" style={{ marginTop: "-40px", paddingRight: "5px" }} onClick={handelClick}> */}

                  {/* <textarea cols={32} placeholder="Comments" className="inputfield"  value={props.comments}></textarea> */}
                </div>
              </div>
            )}

            {props.personnelCode === "LAB" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Consultation Type :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <>
                      {props.personnelCode === "NUR" ? (
                        <Dropdown
                          onChange={(e) => setSelectConsult(e.value)}
                          options={dropdownOptionConsultnurse}
                          optionLabel="name"
                          name="consultationsTypenurse"
                          value={selectConsult ? selectConsult : props?.consultationType}
                          placeholder={"Select Consultation"}
                          className="dropdowntd"
                        />
                      ) : (
                        <Dropdown
                          onChange={(e) => setSelectConsult(e.value)}
                          options={dropdownOptionConsult}
                          optionLabel="name"
                          name="consultationsType"
                          value={selectConsult ? selectConsult : props?.consultationType}
                          // :
                          //   props.consultationType === "H" ?  "Home Visit" :  props.consultationType === "P" ? "Phone" 
                          // :  props.consultationType === "E"  ?  "Eclinic": ""}
                          placeholder="Select Consultation"
                          className="dropdowntd"
                        />
                      )}
                    </>
                  ) : props.consultationType === "V" ? (
                    "Video"
                  ) : props.consultationType === "H" ? (
                    "Home Visit"
                  ) : props.consultationType === "P" ? (
                    "Phone"
                  ) : props.consultationType === "E" ? (
                    "Eclinic"
                  ) : (
                    ""
                  )}

                  <div style={{ color: "red", marginTop: "5px", fontSize: "14px" }}>
                    {errorMessages.consultationType}
                  </div>

                  {/* <input type="text" placeholder="Consultation Type" className="inputfield" value={props.consultationType}/> */}
                </div>
              </div>
            )}
            {props.personnelCode === "LAB" || props.personnelCode === "DR" || props.serviceStatus === "1" ? "" :
              <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px" }}>
                <div style={{ width: "40%" }}>
                  <label>Status :</label>
                </div>
                <div style={{ width: "60%" }}>

                  {isEditing ? (
                    (

                      <>
                        <Dropdown onChange={(e) => setSelectStatus(e.value)} name="status" options={dropdownOptionStatus} optionLabel="name" value={selectStatus}
                          placeholder={"Select Status"} className="dropdowntd" />
                        {error && <div style={{ color: "red" }}>{error}</div>}
                      </>



                    )
                  ) :


                    (props.serviceStatus === "3" ? "Completed" : props.serviceStatus === "5" ? "Cancelled" : props.serviceStatus === "6" ? "Re-Scheduled" : props.serviceStatus === "2" ? "Appointment Booked" : "")
                  }


                </div>

              </div>
            }

            {/* {props.serviceStatus === "2" && props.personnelCode === "NUR" ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Status :</label>
                </div>
                <Dropdown
                  onChange={(e) => setSelectStatus(e.value)}
                  name="status"
                  options={dropdownOptionStatus}
                  optionLabel="name"
                  value={selectStatus}
                  placeholder={"Select Status"}
                  className="dropdowntd"
                />
              </div>
            ) : props.serviceStatus === "2" &&
              (props.consultationType === "P" ||
                props.consultationType === "H") ? (
              <>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <label>Status :</label>
                  </div>
                  <Dropdown
                    onChange={(e) => setSelectStatus1(e.value)}
                    name="status"
                    options={dropdownOptionStatusPhonic}
                    optionLabel="name"
                    value={selectStatus1}
                    placeholder={"Select Status"}
                    className="dropdowntd"
                  />
                </div>
              </>
            ) : (
              ""
            )} */}
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
                float: "right",
              }}
            >
              {props.serviceStatus === "3" || props.serviceStatus === "1" || !isEditing ? "" : (
                (props?.personnelCode === "DR") ? <button
                  type="submit"
                  className="addbutton"
                  onClick={(e) => handleSave(e)}
                >
                  Update
                </button> : (props?.personnelCode === "NUR")
                  ?
                  <button
                    type="submit"
                    className="addbutton"
                    onClick={(e) => handleSave(e)}
                  >
                    Update
                  </button> : <button
                    type="submit"
                    className="addbutton"
                    onClick={(e) => handleUpdateLab(e)}
                  >
                    Update
                  </button>


              )}

              {props.serviceStatus === "1" && isEditing ? (
                <button
                  type="button"
                  className="cancelbutton"
                  onClick={() => {
                    if (!handleValidation()) {
                      setIsEditing(true); // Open the "Edit Information" section if there are no validation errors
                    }
                  }}
                >
                  Book To Confirm
                </button>
              ) : (
                ""
              )}

              {/* {(props.serviceStatus === "2" && props.personnelCode === "NUR") ||
              (props.serviceStatus === "2" &&
                (props.consultationType === "P" ||
                  props.consultationType === "H")) ? (
                <button
                  type="submit"
                  className="addbutton"
                  onClick={(e) => handleUpdate(e)}
                >
                  Update
                </button>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </form>

        <Dialog
          visible={ErrorDialog}
          showHeader={true}
          resizable={false}
          draggable={false}
          blockScroll={false}
          // style={{ width: "1000px" }}
          className=""
          style={{
            width: "390px",
          }}
          //  className="p-20"
          onHide={() => setErrorDialog(false)}
        >
          <div
            style={{
              // width: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <i
              class="pi pi-times-circle"
              style={{ color: "green", fontWeight: "700", fontSize: "75px" }}
            ></i>

            <h4 className="text-xl mt-8" style={{}}>
              {ErrorMsg}
            </h4>
          </div>
        </Dialog>
      </div>

      <Dialog
        visible={openDialog}
        showHeader={true}
        resizable={false}
        draggable={false}
        blockScroll={false}
        // style={{ width: "1000px" }}
        className=""
        header={
          <div className="" style={{ fontSize: "20px", fontWeight: "700" }}>
            Please Confirm !
          </div>
        }
        style={{
          width: "390px",
        }}
        //  className="p-20"
        onHide={() => setDialog(false)}
      >
        <div>
          <div
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "700" }}
          >
            Do you want to confirm the appointment ? Notification will be send
            once confirmed !
          </div>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "47px",
              gap: "50px",
            }}
          >
            {props.personnelCode === "DR" ? (
              <>
                {loading == true ?


                  <button
                    type="submit"
                    style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                    className="addbutton"
                  >
                    {" "}
                    Yes
                  </button>
                  :
                  <button
                    type="submit"
                    style={{ cursor: "pointer" }}
                    className="addbutton"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {" "}
                    Yes
                  </button>
                }

              </>

            ) : props.personnelCode === "NUR" ? (
              <>
                {loading == true ?


                  <button
                    type="submit"
                    style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                    className="addbutton"
                  >
                    {" "}
                    Yes
                  </button>
                  :
                  <button
                    type="submit"
                    style={{ cursor: "pointer" }}
                    className="addbutton"
                    onClick={(e) => handleNurse(e)}
                  >
                    {" "}
                    Yes
                  </button>
                }
              </>
            ) : (
              <>
                {loading == true ?


                  <button
                    type="submit"
                    style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                    className="addbutton"
                  >
                    {" "}
                    Yes
                  </button>
                  :
                  <button
                    type="submit"
                    style={{ cursor: "pointer" }}
                    className="addbutton"
                    onClick={(e) => handleLabSubmit(e)}
                  >
                    {" "}
                    Yes
                  </button>
                }
              </>
            )}
            <button className="addbutton" onClick={closeBothFunct}>
              {" "}
              No
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={tickDialog}
        showHeader={true}
        resizable={false}
        draggable={false}
        blockScroll={false}
        // style={{ width: "1000px" }}
        className=""
        // header={<div className="" style={{ fontSize: "20px", fontWeight: "700" }}>Please Confirm !</div>}
        style={{
          width: "390px",
        }}
        //  className="p-20"
        onHide={() => setTickDialog(false)}
      >
        <div
          style={{
            height: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i
            class="pi pi-check-circle"
            style={{ color: "green", fontWeight: "700" }}
          ></i>
          <h1 className="text-xl mt-8" style={{}}>
            Appointment is booked
          </h1>
        </div>
      </Dialog>
    </>
  );
}
export default CareplanSchedulepopup;
