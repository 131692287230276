import DatePicker from "react-datepicker";
import { useState, React, useRef } from "react";
import { useEffect } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "../CarePlan/index.css";
import moment from "moment";


import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import DoctorAppointmentViewPopup from "./DoctorAppointmentViewPopup.js";

import { Tag } from 'primereact/tag';
import { Dropdown } from "primereact/dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Calendar } from "primereact/calendar";
import SidebarMedicine from "./SidebarMedicine.jsx";
import { Sidebar } from "primereact/sidebar";

function Index() {
 const toast = useRef(null);
    const [searchResults, setSearchResults] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setTodate] = useState();
    const [completedRows, setCompletedRows] = useState({});
    const [sideDialogue, setSideDialog] = useState();
    const [selectedRow, setSelectedRow] = useState();

    const handleSearchSubmit = () => {
        const formattedFromDate = fromDate ? moment(fromDate).format('MM/DD/YYYY') : null;
        const formattedToDate = toDate ? moment(toDate).format('MM/DD/YYYY') : null;

        // Start with the base URL
        let url = `${process.env.REACT_APP_BASEURL}partnerEnquiryForm/list/filter?type=UploadPrescription`;

        // Conditionally add query parameters
        if (formattedFromDate) {
            url += `&fromDate=${formattedFromDate}`;
        }
        if (formattedToDate) {
            url += `&toDate=${formattedToDate}`;
        }

        console.log("Fetching URL:", url);

        http.get(url)
            .then((res) => {
                console.log("Response Data:", res?.data?.Data);
                setSearchResults(res?.data?.Data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        handleSearchSubmit();
    }, [])

    const handleRowClick = (rowData) => {
        setSelectedRow(rowData);
        setSideDialog(true);
    };
    // const handleStatusChange = (selectedValue, rowData) => {
    //     if (selectedValue === "Completed") {
    //         const url = `${process.env.REACT_APP_DOC_URL}PatientDocument/backoffice/complete`;
    //         const payload = {
    //             callBackPrescriptionFlag: "C",
    //             code: rowData.code,
    //         };

    //         console.log("Payload:", payload);

    //         http.put(url, payload)
    //             .then((res) => {
    //                 if (res?.status === 200) {
    //                     console.log("Update Response:", res.data);
    //                     toast.current.show({
    //                         severity: "success",
    //                         summary: "Success",
    //                         detail: "Prescription enquiry marked as completed",
    //                         life: 3000,
    //                     });
    //                     setCompletedRows((prev) => ({
    //                         ...prev,
    //                         [rowData.code]: true,
    //                     }));
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error("Error updating status:", error);
    //             });
    //     }
    // };

  

    return (
        <>
            <ToastContainer />

            <div style={{ display: "flex", gap: "15px", padding: "10px",paddingTop:"40px" }}>
   
   
        <Calendar
            value={fromDate}
            onChange={(e) => setFromDate(e.value)}
            placeholder="Select From Date"
            dateFormat="dd-mm-yy"
            showIcon
        />
        <Calendar
            value={toDate}
            onChange={(e) => setTodate(e.value)}
            placeholder="Select To Date"
            dateFormat="dd-mm-yy"
            showIcon
        />
   
    <button onClick={handleSearchSubmit} className="p-button p-component p-button-primary">
        Search
    </button>
</div>



            {searchResults === undefined ? (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    Select date Range
                    {/* <i
                        className="pi pi-spin pi-spinner"
                        style={{
                            fontSize: "2rem",
                            color: "blue",
                            fontWeight: "700",
                        }}
                    /> */}
                </div>
            ) : (
                <DataTable
                value={searchResults}
                paginator
                rows={50}
                rowsPerPageOptions={[5, 10, 25, 50]}
                globalFilterFields={["patientName", "mobile"]}
                tableStyle={{ padding: "10px" }}
                header={<div className="flex flex-wrap justify-content-end gap-2"></div>}
                rowClassName={(rowData) => (rowData.serviceStatus === 2 ? "rowcolor" : "")}
            >
                <Column
                    sortable
                    header="Patient Name"
                    body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}
                />
               {/* Patient Number Column with filter */}
  <Column
    sortable
    header="Patient Number"
    field="phoneNumber"
    filter  // Enable filtering
    filterPlaceholder="Search Patient Number"  // Placeholder text for filter input
    filterMatchMode="contains"  // Optional: Set filter match mode to 'contains' or 'equals'
  />
                <Column sortable header="Patient email" field="email" />
                <Column
                    sortable
                    header="Prescription"
                    field="photoName"
                    body={(rowData) => (
                        <a
                            href={rowData?.photoName}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                            {rowData.photoName}
                        </a>
                    )}
                />
                <Column
                    header="Status"
                    body={(rowData) => {
                        if (rowData.cartId && rowData.cartStatus === 1) {
                            return <Tag value="Order Process" severity="info" />;
                        } else if (rowData.cartStatus === 0) {
                            return <Tag value="Order Placed" severity="success" />;
                        } else {
                            return <Tag value="Pending" severity="warning" />;
                        }
                    }}
                />
                <Column
                    header="Action"
                    body={(rowData) => {
                        // Show action button only if status is Pending
                        if (rowData.cartStatus === 1 || rowData.cartStatus === 0) {
                            return null; 
                        }
                        return (
                            <span>
                                <i
                                    className="fa fa-pencil-square-o"
                                    aria-hidden="true"
                                    onClick={() => handleRowClick(rowData)}
                                    style={{ cursor: "pointer" }}
                                ></i>
                            </span>
                        );
                    }}
                />
            </DataTable>


            )}

            <Sidebar
                visible={sideDialogue}
                position="right"
                onHide={() => setSideDialog(false)}
                style={{ width: "600px" }}
            >
                <>
                    <SidebarMedicine
                        scheduledata={selectedRow}
                        handleSearchSubmit={handleSearchSubmit} 
                        closeFun={() => setSideDialog(false)}
                    />

                </>
            </Sidebar>
        </>
    );
}
export default Index;