import React, { useEffect, useState } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import { toast } from "react-toastify";

function MembershipStatus({ patientId, cartId, medicineCart, fetchCartList,isMembershipApplied,type }) {
  const [hasMembership, setHasMembership] = useState(null);
  const [membershipCode, setMembershipCode] = useState(null);


  useEffect(() => {
    const fetchMembershipStatus = async () => {
      try {
        const response = await http.get(
          `${process.env.REACT_APP_BASEURL}patientMembership/list?patientId=${patientId}&source=B2C`
        );

        if (response.status === 200 && response.data.data) {
          setHasMembership(true);
          setMembershipCode(response.data.data);
        } else {
          setHasMembership(false);
        }
      } catch (error) {
        console.error("Error fetching membership status:", error);
        setHasMembership(false);
      }
    };

    fetchMembershipStatus();
  }, [patientId]);

  const handleAvailMembership = async () => {
    let url;

    url = (type === "lab") ?  `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${membershipCode}&cartId=${cartId}&serviceType=labTests` : `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${membershipCode}&cartId=${cartId}&serviceType=medicine&amount=${medicineCart.amount}`

   console.log(url,"tgghhg")
    if (membershipCode) {
      try {
        const response = await http.get(
        url
        );

        if (response.status === 200) {
          toast.success("Membership availed successfully!", {
            position: "top-center",
            autoClose: 3000,
          });
          fetchCartList();
         
        } else {
          toast.error("Failed to avail membership.", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("Error availing membership:", error);
      }
    }
  };

  const removeDiscount = async () => {
    try {
      const res = await http.get(
        `${process.env.REACT_APP_BASEURL}PatientCart/list?patientId=${patientId}&status=1`
      );

      if (res) {
        let data = res.data[0];

        // Reset discount amounts and membership ID for medicines
        if (
          data?.patientMedicineOrder?.length &&
          data.patientMedicineOrder[0]?.patientMedicineOrderDetailsList?.length
        ) {
          data.patientMedicineOrder[0].membershipId = 0;
          data.patientMedicineOrder[0].patientMedicineOrderDetailsList.forEach(
            (item) => {
              item.discountAmount = 0;
              item.totalAmount = 0;
            }
          );
        }

        // Reset discount amounts and membership ID for lab tests
        if (
          data?.patientLabTestsOrder?.length &&
          data.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList?.length
        ) {
          data.patientLabTestsOrder[0].membershipId = 0;
          data.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.forEach(
            (item) => {
              item.discountAmount = 0;
              item.totalAmount = 0;
            }
          );
        }

        // Update the cart data with removed discounts
        const result = await http.post(`${process.env.REACT_APP_BASEURL}PatientCart/`, data);

        if (result.status === 200) {
          fetchCartList();
          toast.success("Membership discount removed successfully.", {
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          alert("Server Error cart", "Please try again later");
        }
      } else {
        alert("Server Error cart", "Please try again later");
      }
    } catch (error) {
      console.error("Error removing discount:", error);
      alert("Server Error cart", "Please try again later");
    }
  };

  

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: hasMembership ? "#e6f7e6" : "#fbe9e9",
        color: hasMembership ? "#155724" : "#721c24",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {hasMembership ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="checkbox"
            checked={isMembershipApplied}
            onChange={(e) => {
                if (e.target.checked) {
                  handleAvailMembership();
                } else {
                  removeDiscount();
                }
              }}
            //onChange={handleAvailMembership}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              accentColor: "#28a745",
              cursor: "pointer",
            }}
          />
          <span>Avail Membership</span>
        </div>
      ) : (
        <label
          style={{
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Membership Status: Membership Not Available
        </label>
      )}
    </div>
  );
}

export default MembershipStatus;
