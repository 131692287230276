import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MyDatePickerComponent = ({ date, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(new Date(date));

  const handleDateChange = date => {
    setSelectedDate(date);
    // Call the callback function to update the item.date value
    onDateChange(date);
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="yyyy-MM-dd"
    />
  );
};

export default MyDatePickerComponent;
