import React, { useEffect, useState, useRef } from "react";
import http from "../../../src/Redux/services/http-common.jsx";

import { ToggleButton } from "primereact/togglebutton";
import { Toast } from "primereact/toast";
import Labcart from "./LabCartPage.jsx";
import AddressBook from "./AddressBook.jsx";
import { toast } from "react-toastify";
import MembershipStatus from "./MembershipStatus.jsx";

function SidebarMedicine({
  scheduledata,
  closeFun,
  handleSearchSubmit = () => {},
}) {
  const [SelectcartType, setSelectcartType] = useState(true);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [freeTextSearchValue, setFreeTextSearchValue] = useState("");
  const [freeTextSearchValueLab, setFreeTextSearchValueLab] = useState("");

  const [showDropdown, setShowDropdown] = useState(false);
  const [cart, setCart] = useState([]);
  const [cartId, setCartId] = useState();
  const [medicineCart, setMedicineCart] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasMembership, setHasMembership] = useState(null);
  const [membershipCode, setMembershipCode] = useState(null);
  const [isMembershipApplied, setIsMembershipApplied] = useState(false);
  const [isAddressAdded, setIsAddressAdded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  console.log(scheduledata, "edrdff");

  const ontoggleChange = (e) => {
    setSelectcartType(e.value);
  };

  //   const fetchMembershipStatus = async () => {
  //     try {
  //       const response = await http.get(
  //         `${process.env.REACT_APP_BASEURL}patientMembership/list?patientId=${scheduledata?.patientId}&source=B2C`
  //       );

  //       if (response.status === 200 && response.data.data) {
  //         setHasMembership(true);
  //         setMembershipCode(response.data.data);
  //       } else {
  //         setHasMembership(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching membership status:", error);
  //       setHasMembership(false);
  //     }
  //   };

  //   fetchMembershipStatus();
  // }, []);

  const fetchDropdownOptions = async (query) => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${query}&pageNo=1&pageSize=100`
      );
      const options = response.data.map((item) => ({
        label: item.medicineName,
        value: item,
      }));
      setDropdownOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const fetchCartList = async () => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}cart/list?patientId=${scheduledata?.patientId}`
      );

      const cartId = response?.data?.cartId;
      const cartData = response?.data?.medicineCart;

      // Check if the cartId and medicineCart are null
      if (!cartId && !cartData) {
        setCart([]); // Clear the cart
        setMedicineCart(null); // Clear the medicineCart
        setIsMembershipApplied(false); // Reset membership applied status
        return;
      }

      setCartId(cartId);
      setMedicineCart(cartData);

      if (cartData?.patientMedicineOrderDetailsList) {
        const existingCart = cartData?.patientMedicineOrderDetailsList.map(
          (item) => ({
            label: item.drugName,
            value: {
              id: item.drugCode,
              medid: item.id,
              medicineid: item.id,
              drugName: item.drugName,
              drugCategory: item.drugCategory,
              unitPrice: item.unitPrice,
              discountAmount: item.discountAmount,
              totalAmount: item.totalAmount,
              prescriptionRequired: item.prescriptionRequired,
              vendorId: null,
            },
            quantity: item.quantity,
          })
        );
        setCart(existingCart);
      } else {
        setCart([]); // Clear the cart if no items are present
      }
      console.log(cartData.amount, cartData.paidAmount, "fcgv");
      if (
        cartData &&
        cartData.amount > 0 &&
        cartData.amount !== cartData.paidAmount
      ) {
        setIsMembershipApplied(true);
      } else {
        setIsMembershipApplied(false);
      }
    } catch (error) {
      console.error("Error fetching cart list:", error);
    }
  };

  useEffect(() => {
    // Fetch the existing cart when the component mounts
    if (scheduledata?.patientId) {
      fetchCartList();
    }
  }, [scheduledata?.patientId]);

  useEffect(() => {
    if (freeTextSearchValue.trim() !== "") {
      setShowDropdown(true);
      const delayDebounce = setTimeout(() => {
        fetchDropdownOptions(freeTextSearchValue);
      }, 300);
      return () => clearTimeout(delayDebounce);
    } else {
      setShowDropdown(false);
      setDropdownOptions([]);
    }
  }, [freeTextSearchValue]);

  const addToCart = async (medicine) => {
    // Validation to check if the medicine is already in the cart
    const existingItem = cart.find(
      (item) => item.value.id === medicine.value.id
    );
    if (existingItem) {
      alert("This medicine is already in the cart.");
      return;
    }

    const payload = {
      patientId: scheduledata?.patientId,
      drugCode: medicine?.value.id,
      drugName: medicine.value.medicineName,
      drugCategory: medicine.value.drugCategory,
      medicineTypeOfSell: medicine.value.medicineTypeOfSell,
      medicineType: "",
      unitPrice: Number(medicine?.value?.medicineRate),
      quantity: 1,
      prescriptionRequired: medicine.value.prescriptionRequired,
      vendorId: medicine.value.vendorId,
    };
    console.log(medicine?.value, payload, "dfgvhhh");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}newPatientMedicine/`,
        payload
      );

      console.log("API Response:", response);
      fetchCartList();
      setCart((prevCart) => [...prevCart, { ...medicine, quantity: 1 }]);

      setShowDropdown(false);
      setFreeTextSearchValue("");
    } catch (error) {
      console.error("Error adding medicine to cart:", error);
    }
  };

  const updateQuantity = async (id, newQuantity) => {
    console.log(id, "rggtgb");
    if (newQuantity <= 0) {
      setCart((prevCart) => prevCart.filter((item) => item.value.id !== id));
    } else {
      try {
        // Call the API to update the quantity
        await http.get(
          `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?quantity=${newQuantity}&Id=${id}`
        );

        // Update the local cart state
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.value.id === id ? { ...item, quantity: newQuantity } : item
          )
        );

        // Fetch the updated cart list
        fetchCartList();
      } catch (error) {
        console.error("Error updating quantity:", error);
      }
    }
  };

  const deleteItem = async (id) => {
    try {
      // Call the API to delete the item
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}PatientMedicine/updateDrug?status=0&Id=${id}`
      );

      if (response?.status === 200) {
        await fetchCartList();
        // Remove the deleted item from the cart state
        setCart((prevCart) => prevCart.filter((item) => item.value.id !== id));

        // Fetch the updated cart list from the API to ensure synchronization

        toast.success("Item deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        console.error("Failed to delete item. Status:", response.status);
        toast.error("Failed to delete item. Please try again.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occurred while deleting the item.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  console.log(medicineCart?.paidAmount, "trdtftt");

  const submitCart = async () => {
    try {
      const pinCode = localStorage.getItem("selectedPinCode");

      if (!pinCode) {
        console.error("PinCode is not set. Please select an address.");
        toast.warn("Please select an address to proceed.", {
          position: "top-center",
          autoClose: 3000,
        });

        return;
      }

      // Check service availability
      const serviceCheckResponse = await http.get(
        `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkService?pinCode=${pinCode}`
      );
      console.log(
        serviceCheckResponse.data.data,
        serviceCheckResponse?.data?.status,
        "fgthjn"
      );
      if (serviceCheckResponse?.data?.status === 400) {
        console.error(
          "Service not available for the selected pinCode:",
          pinCode
        );
        toast.warn(
          `Service is not available for the selected PinCode: ${pinCode}`,
          {
            position: "top-center",
            autoClose: 3000,
          }
        );

        return;
      }else if(cart.length === 0){
        console.error(
          "No medicine is present in cart:"
        );
        toast.warn(
          `Please Add Medicine in cart To Proceed`,
          {
            position: "top-center",
            autoClose: 3000,
          }
        );
        return;
      } else {
        const response = await http.post(
          `${process.env.REACT_APP_BASEURL}newPatientMedicine/saveWhatsappUploadedPrescription?cartId=${cartId}&docName=${scheduledata?.photoName}&id=${scheduledata?.id}`
        );

        if (response) {
          const payload = {
            firstname: scheduledata?.firstName,
            email: scheduledata?.email,
            phone: scheduledata?.phoneNumber,
            productinfo: "cartpayment",
            cartid: cartId,
            patientId: scheduledata?.patientId,
            amount: medicineCart.paidAmount,
          };

          const paymentResponse = await http.post(
            `${process.env.REACT_APP_BASEURL}api/payment/generateAndSend`,
            payload
          );
          fetchCartList();
          closeFun();
          handleSearchSubmit();
          toast.success("Cart Updated Succesfully", {
            position: "top-center",
            autoClose: 3000,
          });
          console.log(paymentResponse, "Response from the second API");
        }
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <>
      <div>
        <div
          style={{ display: "flex", marginBottom: "15px", marginTop: "20px" }}
        >
          <div style={{}}>
            <label htmlFor="doctors" className="">
              Cart Type:
            </label>
          </div>
          <div style={{ marginLeft: 20 }}>
            <ToggleButton
              onLabel="Pharmacy"
              offLabel="Lab"
              checked={SelectcartType}
              onChange={(e) => setSelectcartType(e.value)}
            />
          </div>
        </div>

        {SelectcartType ? (
          //  pharmacy cart
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: isAddressAdded ? "#e6f7e6" : "#fbe9e9",
                color: isAddressAdded ? "#155724" : "#721c24",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {isAddressAdded && selectedAddress ? (
                  <div className="address-info">
                    <strong>{selectedAddress.name}</strong>
                    <p>
                      {selectedAddress.address1} {selectedAddress.address2},{" "}
                      {selectedAddress.city}, {selectedAddress.state} -{" "}
                      {selectedAddress.pinCode}
                    </p>
                  </div>
                ) : (
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Address is not Added
                  </label>
                )}
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px",padding:"10px" }}
                onClick={() => setIsVisible(true)}
              >
                <button
                  style={{
                    padding: "5px 10px",
                    border: "none",
                    borderRadius: "4px",
                    color: "#e9e9ec",
                    backgroundColor: "green",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Select/Add Address
                </button>
              </div>
            </div>

            {/* Input Field */}
            <div style={{ marginBottom: "20px" }}>
              <label
                style={{ fontWeight: "bold", fontSize: "14px", color: "#333" }}
              >
                Search Medicine Name:
              </label>
              <div style={{ position: "relative", marginTop: "8px" }}>
                <input
                  type="text"
                  value={freeTextSearchValue}
                  onChange={(e) => setFreeTextSearchValue(e.target.value)}
                  placeholder="Type to search medicine"
                  style={{
                    width: "100%",
                    padding: "12px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "14px",
                    outline: "none",
                  }}
                />
                {showDropdown && dropdownOptions.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      width: "100%",
                      backgroundColor: "#fff",
                      border: "1px solid #ddd",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                      zIndex: 1000,
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {dropdownOptions.map((option, index) => (
                      <div
                        key={index}
                        onClick={() => addToCart(option)}
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          borderBottom: "1px solid #f0f0f0",
                          backgroundColor: "#fff",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f7f7f7")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "#fff")
                        }
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <MembershipStatus
              patientId={scheduledata?.patientId}
              cartId={cartId}
              medicineCart={medicineCart}
              fetchCartList={fetchCartList}
              isMembershipApplied={isMembershipApplied}
              type={"medicine"}
            />

            {/* Cart Display */}
            <div style={{ marginTop: "20px" }}>
              <h3
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "10px",
                }}
              >
                Selected Medicines
              </h3>

              {/* Membership Status Section */}
              {/* <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: hasMembership ? "#e6f7e6" : "#fbe9e9",
        color: hasMembership ? "#155724" : "#721c24",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      {hasMembership ? (
        <>

        <div style={{display:"flex",flexDirection:"row"}}>
          <input
            type="checkbox"
            checked={isMembershipApplied}
            onChange={handleAvailMembership}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              accentColor: "#28a745",
              cursor: "pointer",
            }}
          />
          <span>Avail Membership</span>
          </div>
        </>
      ) : (
        <label
          style={{
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Membership Status: Membership Not Available
        </label>
      )}
    </div> */}

              {cart.length > 0 ? (
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {cart?.map((item) => (
                    <li
                      key={item.value.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{item.label}</span>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "4px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "0.9rem",
                              color: "#999",
                              textDecoration: "line-through",
                            }}
                          >
                            ₹{item.value.unitPrice}
                          </span>
                          <span
                            style={{
                              fontSize: "0.9rem",

                              fontWeight: "bold",
                            }}
                          >
                            ₹{item?.value?.totalAmount}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <button
                          onClick={() =>
                            updateQuantity(item.value.medid, item.quantity - 1)
                          }
                          style={{
                            padding: "5px 10px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#f44336",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          -
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          onClick={() =>
                            updateQuantity(item.value.medid, item.quantity + 1)
                          }
                          style={{
                            padding: "5px 10px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#4CAF50",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          +
                        </button>

                        <button
                          onClick={() => deleteItem(item.value.medid)}
                          style={{
                            padding: "5px 10px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#f44336",
                            color: "#fff",
                            cursor: "pointer",
                            marginLeft: "8px",
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No medicines selected.</p>
              )}
            </div>

            {/* Cart Summary */}
            {medicineCart && (
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                }}
              >
                <h4
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "10px",
                  }}
                >
                  Cart Summary
                </h4>
                <p style={{ marginBottom: "5px" }}>
                  Subtotal:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ₹{medicineCart.amount}
                  </span>
                </p>
                <p style={{ marginBottom: "5px" }}>
                  Discount:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ₹
                    {(medicineCart.amount - medicineCart.paidAmount).toFixed(2)}
                  </span>
                </p>
                <p style={{ marginBottom: "5px" }}>
                  Paid Amount:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ₹{medicineCart.paidAmount}
                  </span>
                </p>
              </div>
            )}

            <div style={{ marginTop: "20px" }}>
              <button
                onClick={submitCart}
                style={{
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "4px",
                  backgroundColor: !isAddressAdded ? "gray" : "#4CAF50",
                  color: "#fff",
                  cursor: "pointer",
                }}
                disabled={!isAddressAdded}
              >
                Submit Cart
              </button>
            </div>
          </div>
        ) : (
          // ""
          <Labcart
            scheduledata={scheduledata}
            closeFun={closeFun}
            isAddressAdded={isAddressAdded}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            setIsAddressAdded={setIsAddressAdded}
          />
        )}
        <AddressBook
          patientId={scheduledata?.patientId}
          isVisible={isVisible}
          onHide={() => setIsVisible(false)}
          cartId={cartId}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          setIsAddressAdded={setIsAddressAdded}
        />
      </div>
    </>
  );
}

export default SidebarMedicine;
