import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import http from "../../../src/Redux/services/http-common.jsx";
import { toast } from "react-toastify";


const AddressBook = ({ patientId, isVisible, onHide,cartId,selectedAddress,setSelectedAddress = () => {},setIsAddressAdded = () => {}  }) => {
  const [addresses, setAddresses] = useState([]);
  const [isAddAddressVisible, setIsAddAddressVisible] = useState(false);

  useEffect(() => {
    if (patientId && isVisible) {
      fetchAddresses();
    }
  }, [patientId, isVisible]);

  const fetchAddresses = async () => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}AddressBook/list/filter?patientId=${patientId}`
      );
      setAddresses(response?.data || []);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const handleRadioChange = async (address) => {
    setSelectedAddress(address);
    try {
       localStorage.setItem("selectedPinCode", address.pinCode);
      console.log("PinCode saved to AsyncStorage:", address.pinCode);
    } catch (error) {
      console.error("Error saving pinCode to AsyncStorage:", error);
    }
  };


  const handleAddAddressClick = () => {
    setIsAddAddressVisible(true);
  };

  const handleAddAddressHide = () => {
    setIsAddAddressVisible(false);
  };

  const handleAddressSaved = () => {
    console.log("Address successfully saved!");
    fetchAddresses(); 
  };

  console.log(selectedAddress,"selectedAddress")
  const handleSaveSelectedAddress = async () => {
    if (!selectedAddress) {
      toast.warn("Please select an address to save.", {
        position: "top-center",
        autoClose: 3000,
      });
     // alert("Please select an address to save.");
      return;
    }
  
    const payload = {
      patientId,
      address1: selectedAddress?.address1,
      address2: selectedAddress?.address2,
      mobile: selectedAddress.mobile || "",
      type: selectedAddress.type,
      isDefault: selectedAddress.isDefault ? 1 : 0,
      pinCode: selectedAddress.pinCode,
      state: selectedAddress.state,
      city: selectedAddress.city,
      country: selectedAddress.country || "",
      status: selectedAddress.status || 1,
      createdDate: new Date().toISOString().slice(0, 19).replace("T", " "),
      modifiedDate: new Date().toISOString().slice(0, 19).replace("T", " "),
      createdBy: patientId,
      modifiedBy: patientId,
    };
  
    try {
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}AddressBook/?cartId=${cartId}&existing=true`,
        payload
      );
      console.log("Selected address saved successfully:", response.data);
      setIsAddressAdded(true)
      onHide();
       toast.success("Address Added in Cart Succesfully", {
                position: "top-center",
                autoClose: 3000,
              });
    } catch (error) {
      console.error("Error saving selected address:", error);
    }
  };
  

  return (
    <Dialog
      header="Saved Address"
      visible={isVisible}
      style={{ width: "450px" }}
      onHide={onHide}
    >
      <div className="address-list">
        {addresses.map((address) => (
          <div key={address.id} className="address-item">
            <div className="address-radio">
              <RadioButton
                inputId={`address-${address.id}`}
                value={address}
                name="address"
                onChange={() => handleRadioChange(address)}
                // onChange={(e) => setSelectedAddress(e.value)}
                checked={selectedAddress?.id === address.id}
              />
            </div>
            <div className="address-info">
              <strong>{address.name}</strong>
              <p>
                {address.address1} {address.address2},{" "}
                {address.city}, {address.state} - {address.pinCode}
              </p>
            </div>


          { address.type?  <div className="address-type">
              {address.type}
            </div> :""}

          </div>
        ))}
      </div>
      <Button
        label="+ Add new address"
        className="p-button-outlined p-button-primary"
        onClick={handleAddAddressClick}
        style={{ marginTop: "15px", textAlign: "center", width: "100%" }}
      />

{isAddAddressVisible && (
        <AddNewAddress
          isVisible={isAddAddressVisible}
          onHide={handleAddAddressHide}
          onAddressSaved={handleAddressSaved}
          patientId={patientId}
          cartId ={cartId}
        />
      )}

<Button
        label="Save Selected Address"
        className="p-button-primary"
        onClick={handleSaveSelectedAddress}
        style={{ marginTop: "20px", textAlign: "center", width: "100%" }}
      />
    </Dialog>
  );
};

const AddNewAddress = ({ isVisible, onHide, patientId, onAddressSaved }) => {
    const [formData, setFormData] = useState({
      address1: "",
      address2: "",
      pincode: "",
      state: "",
      city: "",
      type: "",
      isDefault: false,
    });
  
    const addressTypes = [
      { label: "Home", value: "HOME" },
      { label: "Office", value: "OFFICE" },
      { label: "Others", value: "OTHER" },
    ];
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    };
  
    const handleDropdownChange = (e) => {
      setFormData((prevState) => ({ ...prevState, type: e.value }));
    };
  
    const handleCheckboxChange = (e) => {
      setFormData((prevState) => ({ ...prevState, isDefault: e.checked }));
    };
  
    const handleSave = async () => {
      const payload = {
        patientId,
        address1: formData.address1,
        address2: formData.address2,
        mobile: "", 
        type: formData.type,
        isDefault: formData.isDefault ? 1 : 0,
        pinCode: formData.pincode,
        state: formData.state,
        city: formData.city,
        country: "", 
        status: 1,
        createdDate: new Date().toISOString().slice(0, 19).replace("T", " "),
        modifiedDate: new Date().toISOString().slice(0, 19).replace("T", " "),
        createdBy: patientId,
        modifiedBy: patientId,
      };
  
      try {
        const response = await http.post(`${process.env.REACT_APP_BASEURL}AddressBook/`, payload);
        console.log("Address Saved:", response.data);
        onAddressSaved(); 
        onHide(); 
      } catch (error) {
        console.error("Error saving address:", error);
      }
    };
  
    return (
      <Dialog
        header="Add New Address"
        visible={isVisible}
        style={{ width: "500px" }}
        onHide={onHide}
        modal
      >
      <div className="p-grid p-fluid" style={{ gap: '1rem' }}>
  {/* House Number/Street */}
  <div className="p-col-6" style={{ marginBottom: '1rem' }}>
    <label style={{ display: 'block', marginBottom: '0.5rem' }}>House Number/Street</label>
    <InputText
      name="address1"
      value={formData.address1}
      onChange={handleInputChange}
      placeholder="Please enter your street/house"
      style={{ width: '100%' }}
    />
  </div>

  {/* Address */}
  <div className="p-col-6" style={{ marginBottom: '1rem' }}>
    <label style={{ display: 'block', marginBottom: '0.5rem' }}>Address</label>
    <InputText
      name="address2"
      value={formData.address2}
      onChange={handleInputChange}
      placeholder="Please enter your address"
      style={{ width: '100%' }}
    />
  </div>

  {/* Pincode */}
  <div className="p-col-6" style={{ marginBottom: '1rem' }}>
    <label style={{ display: 'block', marginBottom: '0.5rem' }}>Pincode</label>
    <InputText
      name="pincode"
      value={formData.pincode}
      onChange={handleInputChange}
      placeholder="Please enter your pincode"
      style={{ width: '100%' }}
    />
  </div>

  {/* State */}
  <div className="p-col-6" style={{ marginBottom: '1rem' }}>
    <label style={{ display: 'block', marginBottom: '0.5rem' }}>State</label>
    <InputText
      name="state"
      value={formData.state}
      onChange={handleInputChange}
      placeholder="Please enter your state"
      style={{ width: '100%' }}
    />
  </div>

  {/* City */}
  <div className="p-col-6" style={{ marginBottom: '1rem' }}>
    <label style={{ display: 'block', marginBottom: '0.5rem' }}>City</label>
    <InputText
      name="city"
      value={formData.city}
      onChange={handleInputChange}
      placeholder="Enter city"
      style={{ width: '100%' }}
    />
  </div>

  {/* Type */}
  <div className="p-col-6" style={{ marginBottom: '1rem' }}>
    <label style={{ display: 'block', marginBottom: '0.5rem' }}>Type</label>
    <Dropdown
      value={formData.type}
      options={addressTypes}
      onChange={handleDropdownChange}
      placeholder="Please select your address type"
      style={{ width: '100%' }}
    />
  </div>

  {/* Is Default */}
  <div className="p-col-12" style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
    <Checkbox
      checked={formData.isDefault}
      onChange={handleCheckboxChange}
    />
    <label style={{ marginLeft: '8px' }}>Is Default</label>
  </div>

  {/* Footer Buttons */}
  <div className="p-dialog-footer" style={{ textAlign: 'right', marginTop: '1rem' }}>
    <Button
      label="Cancel"
      className="p-button-outlined"
      onClick={onHide}
      style={{ marginRight: '0.5rem' }}
    />
    <Button
      label="Save"
      className="p-button-primary"
      onClick={handleSave}
    />
  </div>
</div>

      </Dialog>
    );
  };

export default AddressBook;
