import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import http from "../../../src/Redux/services/http-common.jsx";
import "./labcart.css";
import MembershipStatus from "./MembershipStatus.jsx";
import AddressBook from "./AddressBook.jsx";

const LabCartPage = ({
  scheduledata,
  closeFun,
  isAddressAdded,
  selectedAddress,
  setSelectedAddress = () => {},
  setIsAddressAdded = () => {},
}) => {
  const [pincode, setPincode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [labCircle, setLabCircle] = useState("");
  const [cart, setCart] = useState([]);
  const [isMembershipApplied, setIsMembershipApplied] = useState(false);
  const [totalcartsummary, setTotalcartsummary] = useState();
  const toast = React.useRef(null);
  const [cartId, setCartId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [totalCartSummary, setTotalCartSummary] = useState({
    totalAmount: 0,
    paidAmount: 0,
  });
  const [locationid, setLocationid] = useState({
    locationId: "",
    hospitalname: "",
  });

  useEffect(() => {
    if (scheduledata?.patientId) {
      fetchCartList();
    }
  }, [scheduledata?.patientId]);

  const fetchSuggestions = async (query, circle) => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?freeTextSearch=${query}&source=b2c&labCircle=${circle}`
      );
      setSuggestions(response?.data || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value.length > 2 && labCircle) {
      fetchSuggestions(value, labCircle);
    } else {
      setSuggestions([]);
    }
  };

  const checkServiceAvailability = async () => {
    if (!pincode) {
      toast.current.show({
        severity: "warn",
        summary: "Validation",
        detail: "Please enter a pincode.",
      });
      return;
    }

    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkDiagnoServiceAvailabilityCircle?pinCode=${pincode}`
      );
      const circle = response?.data;

      if (circle) {
        setLabCircle(circle);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `Service available for lab circle: ${circle}`,
        });
      } else {
        setLabCircle("");
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "No lab circle available for this pincode.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong while checking service availability.",
      });
    }
  };

  const selectSuggestion = (suggestion) => {
    setSearchText(suggestion.name);
    setSuggestions([]);
  };

  const addToCart = async (labTest) => {
    console.log(labTest, "ggvggg");
    try {
      const payload = {
        patientId: scheduledata?.patientId,
        labTestCode: labTest.labTestCode,
        amount: labTest.amount,
        hospitalId: labTest.labId,
        locationId: labTest.locationId,
        labTestName: labTest.labTestName,
        labTestType: labTest.testType,
        sourceChannel: "B2C",
      };
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}cart/addToCart/labtest`,
        payload
      );

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Added to Cart",
          detail: `${labTest.labTestName} has been added to the cart.`,
        });
        setSuggestions([]);
        fetchCartList();
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Failed",
          detail: "Unable to add the lab test to the cart.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong while adding to the cart.",
      });
    }
  };

  const deleteCartItem = async (id) => {
    console.log(id, "gtfgtg");
    try {
      const response = await http.delete(
        `${process.env.REACT_APP_BASEURL}cart/deleteLabtest?Id=${id}`
      );

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Item Deleted",
          detail: "The item has been successfully removed from the cart.",
        });
        fetchCartList();
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Failed",
          detail: "Unable to delete the item.",
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong while deleting the item.",
      });
    }
  };
  const fetchCartList = async () => {
    try {
      const response = await http.get(
        `${process.env.REACT_APP_BASEURL}cart/list?patientId=${scheduledata?.patientId}`
      );

      const cartId = response?.data?.cartId;
      console.log(cartId, "Cart ID");
      setCartId(cartId);

      const cartData =
        response?.data?.labtestCart?.patientLabTestsOrderDetailsList || [];
      const updatedCart = cartData.map((item) => ({
        id: item.id,
        labTestName: item?.labTestName,
        labName: item?.drugCategory,
        totalAmount: item?.amount,
        membershipCode: item?.membershipCode,
        discountAmount: item?.discountAmount,
      }));
      setCart(updatedCart);
      const totalAmount = response?.data?.labtestCart?.totalAmount || 0;
      const paidAmount = response?.data?.labtestCart?.paidAmount || 0;

      setTotalCartSummary({ totalAmount, paidAmount });

      const locationid = response?.data?.labtestCart?.locationId;
      const hospitalName = response?.data?.labtestCart?.hospitalName;

      setLocationid({
        locationId: locationid || "",
        hospitalname: hospitalName || "",
      });

      if (!cartId || cartData.length === 0) {
        setCart([]);
        setIsMembershipApplied(false);
        return;
      }

      console.log(cartData, "Cart Data");

      const discountAmount = cartData.reduce(
        (acc, item) => acc + (item.discountAmount || 0),
        0
      );
      console.log(cartData, totalAmount, discountAmount, "Membership Applied");
      if (discountAmount) {
        console.log("Membership Applied");
        setIsMembershipApplied(true);
      } else {
        setIsMembershipApplied(false);
      }

      toast.current.show({
        severity: "info",
        summary: "Cart Updated",
        detail: "Cart has been updated with the latest items.",
      });
    } catch (error) {
      console.error("Error fetching cart list:", error);
    }
  };

  const calculateTotalAmount = () => {
    return cart.reduce((total, item) => total + item.totalAmount, 0);
  };

  console.log(totalcartsummary, totalcartsummary?.hospitalName, "esdffggg");

  const submitCart = async () => {
    try {
      const pinCode = localStorage.getItem("selectedPinCode");

      if (!pinCode) {
        console.error("PinCode is not set. Please select an address.");
        toast.current.show({
          severity: "warn",
          summary: "Address Missing",
          detail: "Please select an address to proceed.",
          life: 3000,
        });
        return;
      }

      // Check service availability
      const serviceCheckResponse = await http.get(
        `${process.env.REACT_APP_BASEURL}ServiceAvailability/checkDiagnoServiceAvailability?locationId=${locationid?.locationId}&pinCode=${pinCode}`
      );

      console.log(
        serviceCheckResponse.data.data,
        serviceCheckResponse?.data?.status,
        "fgthjn"
      );

      if (serviceCheckResponse?.data?.status === 400) {
        console.error(
          "Service not available for the selected pinCode:",
          pinCode
        );
        toast.current.show({
          severity: "warn",
          summary: "Service Unavailable",
          detail: `Service is not available for the selected PinCode: ${pinCode}`,
          life: 3000,
        });
        return; // Prevent further execution
      }
      if(cart.length === 0){
              console.error(
                "No medicine is present in cart:"
              );
              toast.warn(
                `Please Add Lab test in cart To Proceed`,
                {
                  position: "top-center",
                  autoClose: 3000,
                }
              );
              return;
            }

      // Proceed with cart submission
      const response = await http.post(
        `${process.env.REACT_APP_BASEURL}newPatientMedicine/saveWhatsappUploadedPrescription?cartId=${cartId}&docName=${scheduledata?.photoName}&id=${scheduledata?.id}`
      );

      if (response) {
        const payload = {
          firstname: scheduledata?.firstName,
          email: scheduledata?.email,
          phone: scheduledata?.phoneNumber,
          productinfo: "cartpayment",
          cartid: cartId,
          patientId: scheduledata?.patientId,
          amount: totalCartSummary.paidAmount,
        };

        const paymentResponse = await http.post(
          `${process.env.REACT_APP_BASEURL}api/payment/generateAndSend`,
          payload
        );

        fetchCartList();
        closeFun();

        toast.current.show({
          severity: "success",
          summary: "Cart Updated",
          detail: "Cart has been updated successfully!",
          life: 3000,
        });

        console.log(paymentResponse, "Response from the second API");
      }
    } catch (error) {
      console.error("Error during cart submission:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while submitting the cart. Please try again later.",
        life: 3000,
      });
    }
  };

  return (
    <div className="container">
      <Toast ref={toast} />
      <div className="form-section">
        <label className="form-label">
          Check Diagnostic Service Availability
        </label>
        <div className="input-group">
          <InputText
            id="pincode"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            placeholder="Enter Pincode"
          />
          <Button
            label="Check Availability"
            icon="pi pi-check"
            className="button-primary"
            onClick={checkServiceAvailability}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: isAddressAdded ? "#e6f7e6" : "#fbe9e9",
          color: isAddressAdded ? "#155724" : "#721c24",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {isAddressAdded && selectedAddress ? (
            <div className="address-info">
              <strong>{selectedAddress.name}</strong>
              <p>
                {selectedAddress.address1} {selectedAddress.address2},{" "}
                {selectedAddress.city}, {selectedAddress.state} -{" "}
                {selectedAddress.pinCode}
              </p>
            </div>
          ) : (
            <label
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Address is not Added
            </label>
          )}
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", gap: "8px",padding:"10px" }}
          onClick={() => setIsVisible(true)}
        >
          <button
            style={{
              padding: "5px 10px",
              border: "none",
              borderRadius: "4px",
              color: "#e9e9ec",
              backgroundColor: "green",
              border: "none",
              cursor: "pointer",
            }}
          >
            Select/Add Address
          </button>
        </div>
      </div>
      <div className="form-section">
        <label className="form-label">Search for Lab Tests</label>
        <div className="input-group">
          <InputText
            value={searchText}
            onChange={(e) => handleSearchChange(e)}
            placeholder="Search for lab tests"
            disabled={!labCircle}
          />
          {/* <Button
        label="Search"
        icon="pi pi-search"
        className="button-primary"
        onClick={() => fetchSuggestions(searchText, labCircle)}
      /> */}
        </div>
        {suggestions.length > 0 && (
          <div className="suggestions-dropdown">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => addToCart(suggestion)}
              >
                <strong>{suggestion.labTestName}</strong>
                <div>{suggestion.labName}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <MembershipStatus
        patientId={scheduledata?.patientId}
        cartId={cartId}
        isMembershipApplied={isMembershipApplied}
        fetchCartList={fetchCartList}
        type={"lab"}
      />
      <div className="cart-section">
        {cart.length > 0 ? (
          <ul className="cart-list">
            {cart.map((item, index) => (
              <li key={index} className="cart-item">
                <div>
                  <strong>{item.labTestName}</strong>
                  {isMembershipApplied === true ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#999",
                          textDecoration: "line-through",
                        }}
                      >
                        ₹{item.totalAmount}
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        ₹{item.discountAmount}
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#999",
                          fontWeight: "bold",
                        }}
                      >
                        ₹{item.totalAmount}
                      </span>
                    </div>
                  )}
                </div>
                <Button
                  icon="pi pi-trash"
                  className="button-danger"
                  onClick={() => deleteCartItem(item.id)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p>Your cart is empty.</p>
        )}

        {/* Cart Summary Section */}
        <div
          style={{
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        >
          <h4
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              marginBottom: "10px",
            }}
          >
            Cart Summary
          </h4>
          <p style={{ marginBottom: "5px" }}>
            Subtotal:{" "}
            <span style={{ fontWeight: "bold" }}>
              ₹{totalCartSummary.totalAmount}
            </span>
          </p>
          <p style={{ marginBottom: "5px" }}>
            Discount:{" "}
            <span style={{ fontWeight: "bold" }}>
              ₹
              {(
                totalCartSummary.totalAmount - totalCartSummary.paidAmount
              ).toFixed(2)}
            </span>
          </p>
          <p style={{ marginBottom: "5px" }}>
            Paid Amount:{" "}
            <span style={{ fontWeight: "bold" }}>
              ₹{totalCartSummary.paidAmount.toFixed(2)}
            </span>
          </p>
        </div>

        <div style={{ marginTop: "5px", textAlign: "right" }}>
          <Button
            label="Submit"
            icon="pi pi-check"
            className={isAddressAdded ? "button-success" : "btn-secondary"}
            onClick={submitCart}
            disabled={!isAddressAdded}
          />
        </div>
      </div>

      <AddressBook
        patientId={scheduledata?.patientId}
        isVisible={isVisible}
        onHide={() => setIsVisible(false)}
        cartId={cartId}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        setIsAddressAdded={setIsAddressAdded}
      />
    </div>
  );
};

export default LabCartPage;
