import "./index.css";
import { useDispatch } from "react-redux";
import axios from 'axios';
import React, { useState, useEffect } from "react";
import DateView from "../../components/DateView/dateView";
import SearchView from "../../components/SearchView/searchView";
import MedicineOrderList from "./MedicineOrderList";
import moment from "moment";
import DropDownCancelReson from "../../components/DropDownCancelResonBusiness";
import http from "../../../src/Redux/services/http-common.jsx"
import { ToastContainer, toast } from "react-toastify";
import {
  getBusinessEvent,
  getOrderDetails,
  onAddComment,
  updatePreferTime
} from "../../Redux/Actions/dashboardaction";


import InfiniteScroll from "react-infinite-scroll-component";

import { onConvertJsonToExcel } from "../../utils/JsonToExcelConverter";

const SmartLabReports = () => {
  const dispatch = useDispatch();
  const [isSelectedOption] = useState("Smart Lab Reports");

  const [fromDate, setFromDate] = useState(
    moment().add(-1, "days").format("DD-MM-yyyy")
  );
  const [toDate, setToDate] = useState(
    moment().add("days").format("DD-MM-yyyy")
  );
  const [hasMore, setHasMore] = useState(true);
  const [pageStart, setPageStart] = useState(1);
  const [isSelectedService, setSelectedService] = useState("2");
  const [businessEventData, setBusinessEventData] = useState([]);

  const [filterKey, setFilterKey] = useState("");
  const [isLoader, setLoader] = useState(false);

  const [isStatus, setStatus] = useState(1);
  const [translatedapi,setTranslatedapi] = useState([]);
  const [firstName, setFirstName] = useState("")
  const [patientCode, setPatientCode] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [email, setEmail] = useState("")
//   const [statusOfBusiness] = useState([

   
//     {
//       status: 1,
//       statusText: "Ordered",
//     }
//   ]);


  useEffect(() => {
    // let payload = {
    //     fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    //     toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    //     pageNo: "1",
    //     pageSize: "20",
    //     type: "2",
    //     status: 1,
    //   };
    fetchList()
  }, []);

  const fetchList = (pageSize= '100', pageNo = 1) =>{
    const params = new URLSearchParams();
    if (firstName.trim()) params.append("firstName", firstName);
    if (email.trim()) params.append("email", email);
    if (patientCode.trim()) params.append("patientCode", patientCode);
    if (mobileNumber.trim()) params.append("mobileNumber", mobileNumber);
    params.append("pageSize", pageSize);
    params.append("pageNo", pageNo);

    const queryString = params.toString();
    http.get(`${process.env.REACT_APP_BASEURL}backoffice/patientDocument/list/filter${queryString ? `?${queryString}` : ""}`)
    .then(response => {
        setFirstName("");
        setEmail("");
        setPatientCode("");
        setMobileNumber("")
        // if(translatedapi.length >= response.data[0].totalSize){
        //     setHasMore(false)
        // }else{
        //     setHasMore(true)
        //     setPageStart(pageStart + 1);
        //     if(translatedapi?.length){
        //         setTranslatedapi([...translatedapi, ...response.data])
        //     }else{
                setTranslatedapi(response.data)
        //     }
           
        // }
        
    }
       
        )
       
        
    .catch(error => console.log(error));
  }


  const [translatedPres] = useState([
    "Patient Details",
    "Mobile Number",
    "Created Date",
    "Status",
    "Action"
  ]);


  const loadFunc = async () => {
    if(hasMore){
        fetchList(pageStart + 1, '10')
    }
  };


  return (
    <>

    <ToastContainer/>
      <div className="profilepage-breadcrumb"></div>

      <div className="titleBusinessEvent"> Select Filters & Services:</div>

      <div className="dateContainer">
        <div className="dateContainerSub">
        <SearchView
            title="Search by patient Code"
            onTextChange={(value) => setPatientCode(value)}
            onClickSearch={fetchList}
            onKeyDownEnter={(e) => e?.key == "Enter" && fetchList()}
            filterKey={patientCode}
          />

          <SearchView
            title="Search by Mobile Number"
            onTextChange={(value) => setMobileNumber(value)}
            onClickSearch={fetchList}
            onKeyDownEnter={(e) => e?.key == "Enter" && fetchList()}
            filterKey={mobileNumber}
          />
        </div>

        <div className="dateContainerSub">
        <SearchView
            title="Search by First Name"
            onTextChange={(value) => setFirstName(value)}
            onClickSearch={fetchList}
            onKeyDownEnter={(e) => e?.key == "Enter" && fetchList()}
            filterKey={firstName}
          />
          <SearchView
            title="Search by Email"
            onTextChange={(value) => setEmail(value)}
            onClickSearch={fetchList}
            onKeyDownEnter={(e) => e?.key == "Enter" && fetchList()}
            filterKey={email}
          />
          
          {/* <DropDownCancelReson
         
          onArrowClick={handleDropDownClick}
          onOptionClick={handleDropDownSelectStatus}
          options={statusOfBusiness}
          placeholder="Select Service"
          isPalaceHolder={isPalaceHolder}
        /> */}

        </div>
      </div>

     

      <p className="hospitalEnquiry">Service: {isSelectedOption}</p>

      <div className="hospitalEnquiryBody">
        {fromDate && toDate ? (
          <div className="selectedDateContainer">
          </div>
        ) : (
          <div></div>
        )}

        <div className="hospitalEnquiryBody">
          <p className="response">
            {" "}
            Total there are {translatedapi?.length} responses
          </p>

        </div>
      </div>

      <div
        id="scrollableDiv"
        className="listNewContainer"
        style={{
          height: 800,
          overflow: "auto",
        }}
      >
        {/* <InfiniteScroll
          dataLength={businessEventData.length}
          next={loadFunc}
          hasMore={hasMore}
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        ></InfiniteScroll> */}

        <MedicineOrderList
          medicineList={translatedapi}
          doctorConsultation={translatedPres}
          isLoader={isLoader}
          fetchList = {fetchList}
        />
      </div>
    </>
  );
};

export default SmartLabReports;
